import * as React from "react";
import Navbar from "../components/navbar";
import * as style from "./index.module.css";
import * as biostyle from "../components/bio.module.css";
import "animate.css";
import Gallery from "../components/gallery";
import { Script, Link } from "gatsby";
import { MdArrowBack } from "@react-icons/all-files/md/MdArrowBack";

// define constants
const NAME = "leo martinez";
const EMAIL = "bleo@mit.edu";

const ProjectsPage = () => {
  return (
    <div className={`${style.viewport} ${style.gallery_scroll}`}>
      <Script
        src="https://kit.fontawesome.com/cde1a27a61.js"
        crossorigin="anonymous"
      ></Script>
      <Navbar name={NAME} email={EMAIL}></Navbar>
      <Link className={biostyle.back_button} to="/">
        <MdArrowBack size="2em" />
      </Link>
      <div
        className={`${biostyle.big_bold_text} animate__animated animate__fadeIn`}
      >
        RECENT PROJECTS
      </div>
      <Gallery></Gallery>
    </div>
  );
};

export default ProjectsPage;
