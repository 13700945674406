/* eslint-disable space-before-function-paren */
import Img from "gatsby-image";
import React from "react";
import { useStaticQuery, graphql, navigate } from "gatsby";
import "./gallery.css";

/* Randomize array in-place using Durstenfeld shuffle algorithm */
function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
}

const useGallery = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { sourceInstanceName: { eq: "gallery" } }) {
        nodes {
          id
          relativeDirectory
          relativePath
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `);

  return data.allFile.nodes.map((node) => ({
    ...node.childImageSharp, // Note that we're spreading the childImageSharp object here
    id: node.id,
    directory: node.relativeDirectory,
    filename: node.relativePath,
  }));
};

const Gallery = () => {
  const images = useGallery();
  shuffleArray(images);

  return (
    <div className="gallery">
      {images.map(({ id, directory, filename, fluid }) => (
        <div key={id} className="image_wrapper">
          <Img key={id} fluid={fluid} className="image_content" />
          <div
            key={id}
            className="img__description"
            onClick={() => {
              navigate(`/projects/${directory}`);
            }}
          ></div>
        </div>
      ))}
    </div>
  );
};

export default Gallery;
